<template>
  <b-card>
    <div v-if="isNexos" class="text-center p-5">
      <h3 class="text-danger mb-2">{{ $t('common.forbidden') }}</h3>
      <p>{{ $t('common.access_denied') }}</p>
    </div>
    <template v-else>
      <div class="d-flex justify-content-between w-100 my-1 mb-2 pl-1">
        <div class="d-flex align-items-center float-left">
          <!--a class="mr-50" @click="$router.go(-1)">
            <feather-icon icon="ChevronLeftIcon" size="20" />
          </a-->
          
          <!-- App Title -->
          <h3 class="m-0 font-weight-600">
            {{ appTitle || $t("storage.title") }}
          </h3>
        </div>

        <div class="d-flex align-items-center">
          <widget-actions
            item-type="storage"
            :show-add="canAdd"
            :show-create-folder="canAdd"
            :is-media="true"
            :show-list="false"
            :show-filter="false"
            @updateSearch="searchItems"
            @openMediaModal="isAddingMedia = true"
            @openFolderModal="openFolderModal"
          />
        </div>
      </div>

      <!-- Table of storage folders and files -->
      <storage-table :is-widget="false" @updateFolders="updateFolders" />

      <!-- Create Folder modal -->
      <b-modal
        id="modal-create-folder"
        :title="$t('storage.create-folder')"
        centered
        size="lg"
        hide-footer
      >
        <create-folder-modal
          ref="storageTable"
          :folder-key="folders.length > 0 ? folders[folders.length - 1].key : null"
        />
      </b-modal>

      <!-- Upload File modal -->
      <media-upload-modal
        v-model="isAddingMedia"
        media-type="file"
        :from-storage="true"
        :folder-key="folders.length > 0 ? folders[folders.length - 1].key : null"
        @mediaCreated="mediaCreated"
      />
    </template>
  </b-card>
</template>

<script>
import WidgetActions from '@/@core/components/widget-actions/WidgetActions.vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import moment from 'moment';
import { checkPermissions } from '@/@core/utils/roles-utils';
import StorageTable from '@/views/apps/storage/components/StorageTable.vue';
import CreateFolderModal from '@/views/apps/storage/components/CreateFolderModal.vue';
import MediaUploadModal from '@/views/apps/media/components/modal/MediaUploadModal.vue';

export default {
  name: 'StorageList',
  components: {
    WidgetActions,
    StorageTable,
    CreateFolderModal,
    MediaUploadModal,
  },
  data() {
    return {
      showfilter: false,
      isAddingMedia: false,
      folders: [],
    };
  },
  computed: {
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    appId() {
      return 16;
    },
    app() {
      return this.$store.getters.apps.apps[this.appId];
    },
    appTitle() {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.app?.customizationName,
      );
    },
    mainCollective() {
      return this.$store.getters.mainCollective;
    },
    mainSpace() {
      return this.mainCollective.key ? this.mainCollective : this.collective;
    },
    isNexos() {
      return process.env.VUE_APP_CUSTOMER === 'nexos' || this.mainSpace?.key === 'f3166db0-b635-11ef-805d-f79ce25412de';
    },
    canAdd() {
      return checkPermissions(
        'create',
        'storage',
        this.loggedMemberRoles,
        this.collective,
      );
    },
    canList() {
      return checkPermissions(
        'view',
        'storage',
        this.loggedMemberRoles,
        this.collective,
      );
    },
    canView() {
      return checkPermissions(
        'view',
        'storage',
        this.loggedMemberRoles,
        this.collective,
      );
    },
  },
  created() {
    this.updateBreadcrumbs();
  },
  methods: {
    translate(field) {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        field,
      );
    },
    timestampToTime(timestamp) {
      moment.locale(this.currentLocale);
      return moment(timestamp).format('DD MMM YYYY - HH:mm');
    },
    async mediaCreated() {
      //
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: this.appTitle || this.$t('storage.title'),
          active: true,
        },
      ];
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
    openFolderModal() {
      this.$bvModal.show('modal-create-folder');
    },
    searchItems(value) {
      this.searchInput = value;
      const searchTerm = value.toLowerCase().trim();
      if (!searchTerm) {
        return;
      }
      this.results = this.itemsData.unpaginated.filter((item) => item.title.toLowerCase().includes(searchTerm));
    },
    updateFolders(folders) {
      this.folders = folders;
    },
  },
};
</script>

<style></style>
